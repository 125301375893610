const fileToBase64 = async (file) => {
    // return new Promise((resolve, reject) => {
    //     const reader = new FileReader();
    //     reader.readAsDataURL(file);
    //     reader.onload = () => {
    //         let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
    //         if ((encoded.length % 4) > 0) {
    //             encoded += '='.repeat(4 - (encoded.length % 4));
    //         }
    //         resolve(encoded);
    //     };
    //     reader.onerror = error => reject(error);
    // });
    var reader = new FileReader();
    return new Promise((resolve, reject) => {
        reader.onerror = () => { reader.abort(); reject(new Error("Error parsing file")); }
        reader.onload = function () {

            let bytes = Array.from(new Uint8Array(this.result));

            let base64StringFile = btoa(bytes.map((item) => String.fromCharCode(item)).join(""));
            base64StringFile = `data:${file.type};base64,${base64StringFile}`
            resolve(base64StringFile);
        }
        reader.readAsArrayBuffer(file);
    });
}

export { fileToBase64 }