import { Divider, Drawer, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Link } from "react-router-dom"
import { routes } from "../../config/routes";
import { AppName } from "../../config";
import { useContext } from "react";
import LanguageContext from "../../context/LanguageContext";


function AppDrawer({ open, drawerWidth, headerHeight }) {

    const { getString } = useContext(LanguageContext)


    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
            }}
            variant="persistent"
            anchor="left"
            open={open}
        >
            <div style={{ height: headerHeight, display: 'flex', justifyContent: 'center', alignItems: 'center ' }}>
                <div style={{ textAlign: 'center' }}>
                    {/* {AppName} */}

                    <img src={'/logo.png'} style={{ height: 60 }}></img>

                </div>
            </div>
            <Divider />
            <List>
                {routes.map((route, index) => (
                    Boolean(route.label)
                    &&
                    <Link key={index} to={route.path} style={{ textDecoration: 'none', color: 'black' }}>
                        <ListItem button>
                            <ListItemIcon>
                                {route.icon}
                            </ListItemIcon>
                            <ListItemText>
                                {getString(route.label)}
                            </ListItemText>
                        </ListItem>
                    </Link>
                ))}
            </List>
        </Drawer >
    )
}

export default AppDrawer;