import { Button, FormControl, InputLabel, MenuItem, Select, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useEffect, useState, useContext, useRef } from "react";
import http from "../../http";
import { apiRoutes, getApiRoute } from "../../utils/api-routes";
import LanguageContext from "../../context/LanguageContext";
import { baseUrl } from "../../utils/api-routes";

const Filter = ({ handleChange, filterFields, getData, handleSubmitFilter, clearFilter }) => {
    const { getString } = useContext(LanguageContext)
    const [options, setOptions] = useState({
        products: [],
        buy: [{ label: getString('Yes'), value: 'yes' },
        { label: getString('No'), value: 'no' }],
    })


    useEffect(async () => {
        const url = getApiRoute(['products', 'get'])
        const response = await http.get(url, { all: 1 })
        setOptions(old => {
            return { ...old, products: response }
        })
    }, [])


    const ExportFile = () => {
        window.location = `${baseUrl}/export`
        console.log('export')
    }

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <>
            <div><h2 style={{ margin: 0 }}>{getString('Filter')}</h2></div>
            <div style={{ marginBottom: '30px' }}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel>{getString('Products')}</InputLabel>
                    <Select
                        value={filterFields['products']}
                        onChange={e => handleChange(e, 'products')}
                        multiple={true}
                        label={getString("Products")}
                    >
                        {options['products']?.map(option => (
                            <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <TextField
                        variant="standard"
                        required={false}
                        style={{ margin: `0 8px` }}
                        type={'date'}
                        value={filterFields['date_from']}
                        name={'date_from'}
                        id="outlined-required"
                        label={getString('Date From')}
                        onChange={e => handleChange(e, 'date_from')}
                    />
                </FormControl>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <TextField
                        variant="standard"
                        required={false}
                        style={{ margin: `0 8px` }}
                        type={'date'}
                        value={filterFields['date_to']}
                        name={'date_to'}
                        id="outlined-required"
                        label={getString('Date To')}
                        onChange={e => handleChange(e, 'date_to')}
                    />
                </FormControl>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel>{getString('Buy')}</InputLabel>
                    <Select
                        value={filterFields['buy']}
                        onChange={e => handleChange(e, 'buy')}
                        label={getString("Buy")}
                    >
                        {options['buy']?.map(option => (
                            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <Button variant="contained"
                        onClick={handleSubmitFilter}
                    >{getString(`Submit`)}</Button>
                </FormControl>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <Button variant="contained"
                        onClick={clearFilter}
                    >{getString(`Clear`)}</Button>
                </FormControl>
                {/* <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <Button variant="contained"
                        onClick={ExportFile}
                    >{getString(`Export`)}</Button>
                </FormControl> */}


            </div >
        </>

    )
}

export default Filter;