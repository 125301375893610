import { AppBar, Avatar, Box, Button, IconButton, Menu, MenuItem, Toolbar, Tooltip, Typography, Badge } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { AppName, languages } from "../../config";
import { useEffect, useContext, useState } from "react";
import NotificationsIcon from '@mui/icons-material/Notifications';
import LanguageIcon from '@mui/icons-material/Language';
import Dropdown from "./Dropdown";
import AuthContext from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import LanguageContext from "../../context/LanguageContext";
import NotificationsContext from "../../context/NotificationsContext";
import Order from "../Order";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect, Routes
} from "react-router-dom";
import { routes } from "../../config/routes";


const pages = [];


function Header({ open, handleDrawerOpen, drawerWidth, headerHeight }) {
    const { getString } = useContext(LanguageContext)

    const { user, logout } = useContext(AuthContext)
    const { changeLanguage } = useContext(LanguageContext)
    const { notificationsList } = useContext(NotificationsContext)

    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [anchorElNotification, setAnchorElNotification] = useState(null);
    const [anchorElLanguage, setAnchorElLanguage] = useState(null);
    let navigate = useNavigate();

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenNotification = (event) => {
        setAnchorElNotification(event.currentTarget);
    };
    const handleOpenLanguage = (event) => {
        setAnchorElLanguage(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseNotification = () => {
        setAnchorElNotification(null);
    };
    const handleCloseLanguage = () => {
        setAnchorElLanguage(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleChangeLanguage = (item) => {
        console.log(item.key)
        changeLanguage(item.key)
    };
    const handleNotificationClick = (item) => {
        console.log(item.key, 'clicked')
        navigate('/orders', { replace: true })
    };



    const handleLogout = async () => {

        try {
            const response = await logout()
            if (response) {
                navigate('/login', { replace: true })
            }
        } catch (error) {
            console.log(error)
        }
    };

    const settings = [
        {
            label: "Logout",
            onClick: handleLogout
        }
    ]


    useEffect(() => {
        //updateNotificationsCount();
    }, []);



    return (
        <AppBar position='absolute' style={{ ...(open && { left: `${drawerWidth}px` }), ...(open && { width: `calc(100% - ${drawerWidth}px)` }), height: `${headerHeight}px` }}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{ mr: 2, ...(open && { display: 'none' }) }}
                >
                    <MenuIcon />
                </IconButton>
                {/* <h2>{AppName}</h2> */}







                <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
                >
                    {AppName}
                </Typography>
                {/* <h2>{AppName}</h2> */}


                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="inherit"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                            display: { xs: 'block', md: 'none' },
                        }}
                    >
                        {pages.map((page) => (
                            <MenuItem key={page} onClick={handleCloseNavMenu}>
                                <Typography textAlign="center">{page}</Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>
                <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
                >
                    {AppName}
                </Typography>
                {/* <h2>{AppName}</h2> */}
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                    {pages.map((page) => (
                        <Button
                            key={page}
                            onClick={handleCloseNavMenu}
                            sx={{ my: 2, color: 'white', display: 'block' }}
                        >
                            {page}
                        </Button>
                    ))}
                </Box>


                <Dropdown
                    handleOpen={handleOpenNotification}
                    tooltip={`Notifications`}
                    label={<NotificationsIcon />}
                    open={anchorElNotification}
                    handleClose={handleCloseNotification}
                    items={notificationsList.map(v => ({ label: getString('Agent "') + v.name + getString('" has not purchased in a while.'), onClick: handleNotificationClick }))}
                    style={{ marginRight: `20px` }}
                    badge={notificationsList.length}
                />

                <Dropdown
                    handleOpen={handleOpenLanguage}
                    tooltip={`Languages`}
                    label={<LanguageIcon />}
                    open={anchorElLanguage}
                    handleClose={handleCloseLanguage}
                    items={languages.map(lang => ({ ...lang, onClick: handleChangeLanguage }))}
                    style={{ marginRight: `20px` }}
                />

                <Box sx={{ flexGrow: 0 }}>
                    <Dropdown
                        handleOpen={handleOpenUserMenu}
                        tooltip={`Open settings`}
                        label={<Avatar alt={user.name} src="/static/images/avatar/2.jpg" />}
                        open={anchorElUser}
                        handleClose={handleCloseUserMenu}
                        items={settings}
                    />
                    {/* <Tooltip title="Open settings">
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                        </IconButton>
                    </Tooltip>
                    <Menu
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                        {settings.map((setting) => (
                            <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                <Typography textAlign="center">{setting}</Typography>
                            </MenuItem>
                        ))}
                    </Menu> */}
                </Box>


            </Toolbar>
        </AppBar>
    );
}

export default Header;