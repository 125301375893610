import { useState } from "react";
import CRUD from "../CRUD";

function Size() {
    const [cells, setCells] = useState([
        {
            id: 'name',
            numeric: false,
            disablePadding: false,
            label: 'Name',
        },
        {
            id: 'total',
            numeric: false,
            disablePadding: false,
            label: 'Total Products',
            value: (params) => {
                console.log(params)
                return 'params'
            }
        }
    ])

    const [fields, setFields] = useState([
        {
            id: 'name',
            required: true,
            label: 'Name',
            type: 'text'
        }
    ])


    return (
        <CRUD
            cells={cells}
            title={`Sizes`}
            titleSingle={`Size`}
            route={`sizes`}
            fields={fields}
        />
    );
}

export default Size;