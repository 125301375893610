function Box({ icon, title, number }) {
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '25px 20px', background: '#eee', borderRadius: '20px', marginLeft: '20px' }}>
            {icon}
            <div style={{ marginLeft: '30px' }}>
                <h3 style={{ display: 'block', margin: '0px' }}>{number}</h3>
                <h6 style={{ margin: '0px' }}>{title}</h6>
            </div>
        </div>
    )
}

export default Box;