import { CircularProgress, TableCell, TableRow } from "@mui/material";

function TableLoading({ colSpan }) {
    return (
        <TableRow>
            <TableCell style={{ position: `relative` }} colSpan={colSpan}>
                <CircularProgress style={{ position: `relative`, left: `50%`, top: `50%`, transform: `translate(-50%,-50%)` }} />
            </TableCell>
        </TableRow>
    )
}

export default TableLoading;