import { useContext } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { Input, TextField } from '@mui/material';

const defaultEmail = ''
const defaultPassword = ''
// const defaultEmail = 'a@a.com'
// const defaultPassword = '123456'

function Login() {
    let navigate = useNavigate();
    let location = useLocation();
    let { user, login } = useContext(AuthContext);


    const from = location.state?.from?.pathname || '/dashboard';

    if (user) {
        return <Navigate to={from} state={{ from: location }} replace />;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!event.currentTarget.checkValidity()) {
            alert('Form not Valid')
            return false
        }

        const formData = new FormData(event.currentTarget)
        const email = formData.get('email')
        const password = formData.get('password')

        try {
            const loginResult = await login(email, password)
            if (loginResult) {
                navigate(from, { replace: true })
            }
        } catch (error) {
            console.log(error)
        }

    }

    const inputMarginBottom = `20px`

    return (
        <div style={{ background: `#fff`, padding: `30px`, borderRadius: `10px` }}>
            <form onSubmit={handleSubmit}>
                <div style={{ minWidth: `300px`, marginBottom: inputMarginBottom }}>
                    <TextField style={{ width: `100%` }} label="Email" variant="standard" name="email" required={true} type={`email`} defaultValue={defaultEmail} />
                </div>
                <div style={{ minWidth: `300px`, marginBottom: inputMarginBottom }}>
                    <TextField style={{ width: `100%` }} label="Password" variant="standard" name="password" required={true} type={`password`} defaultValue={defaultPassword} />
                </div>
                <div style={{ minWidth: `300px`, marginTop: `30px` }}>
                    <Input type={`submit`} style={{ width: `100%` }}></Input>
                </div>
            </form>
        </div>
    )
}

export default Login;