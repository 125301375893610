import { useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { useState } from "react";
import { CircularProgress, Input } from '@mui/material';
import AppTableToolbar from './AppTableToolbar'
import AppTableHead from './AppTableHead'
import TableLoading from './Loading'
import http from '../../http';
import { getApiRoute } from '../../utils/api-routes';


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


function AppTable({ route, rows, setFilterParams, title, headCells, rowsPerPage, handleChangeRowsPerPage, page, getData, handleChangePage, pagination, onDeleteHandler, onEdit, onView, onlyShow, onSendingMessage, onExportingOrder, onExportingProduct, onExportingAgent }) {
    page = page - 1
    page = page < 0 ? 0 : page
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [dense, setDense] = useState(false);
    const [filteredRows, setFilteredRows] = useState([]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const columnSearchHandle = async (event, column) => {
        if (event.currentTarget.value != '') {
            setFilterParams(old => {
                const newParams = {
                    ...old,
                    [column.id.split('.')[0]]: {
                        // name: column.id.split('.')[0],
                        type: column?.filterType,
                        ...(column?.filterType == 'relation' && { relation_field: column?.relationField }),
                        op: 'LIKE',
                        value: event.target.value
                    }
                }
                return newParams
            })
        } else {
            setFilterParams(old => {
                const oldP = old
                delete oldP[column.id.split('.')[0]]
                return { ...oldP }
            })
        }
        // getData()
        // const url = getApiRoute([route, 'search'])
        // const response = await http.post(url, {
        //     search: {
        //         [column.id.split('.')[0]]: {
        //             type: column?.filterType,
        //             ...(column?.filterType == 'relation' && { relation_field: column?.relationField }),
        //             op: 'LIKE',
        //             value: event.currentTarget.value
        //         }
        //     }
        // })
        // setRows(response.data)
        // setFilteredRows(response.data)
        return;

        //to enable  client side filtering remove the return above 
        if (event.currentTarget.value != '') {
            const filtered = rows.filter(row => {
                const keys = column['id'].split('.')
                let name = String(Boolean(column?.getValue) ? column.getValue(row) : keys.reduce((oldValue, item) => (oldValue[item] || '-'), row))
                return name?.toLowerCase()?.includes(event.currentTarget.value.toLowerCase()) || false
            })
            setFilteredRows(filtered.length > 0 ? filtered : [''])
        } else {
            setFilteredRows([])
        }
    }

    const handleClick = (event, name) => {
        if (Boolean(onlyShow)) return

        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const handleOnDelete = () => {
        setSelected([]);
        onDeleteHandler(selected)
    };

    const handleOnEdit = () => {
        setSelected([]);
        onEdit(selected)
    };

    const handleOnView = () => {
        setSelected([]);
        onView(selected)
    };
    const handleonSendingMessage = () => {
        setSelected([]);
        onSendingMessage(selected)
    };
    const onExportOrderHandler = () => {
        setSelected([]);
        onExportingOrder(selected)
    };
    const onExportProductHandler = () => {
        setSelected([]);
        onExportingProduct(selected)
    };
    const onExportAgentHandler = () => {
        setSelected([]);
        onExportingAgent(selected)
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0;

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <AppTableToolbar numSelected={selected.length} title={title} onSendingMessage={handleonSendingMessage} onExportingOrder={onExportOrderHandler} onExportingProduct={onExportProductHandler} onExportingAgent={onExportAgentHandler} onDelete={handleOnDelete} onEdit={handleOnEdit} onView={handleOnView} onlyShow={onlyShow} />
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <AppTableHead
                            numSelected={selected.length}
                            order={order}
                            columnSearchHandle={columnSearchHandle}
                            headCells={headCells}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={(rows?.length || 0)}
                            onlyShow={onlyShow}
                        />
                        <TableBody>
                            {
                                Boolean(rows) ?
                                    stableSort(Boolean(filteredRows.length == 0) ? rows : filteredRows, getComparator(order, orderBy))
                                        // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            const isItemSelected = isSelected(row.id);
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return (
                                                <TableRow
                                                    hover
                                                    onClick={(event) => handleClick(event, row.id)}

                                                    role="checkbox"
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={row.id}
                                                    selected={isItemSelected}
                                                >
                                                    {!Boolean(onlyShow) && <TableCell padding="checkbox">
                                                        <Checkbox
                                                            color="primary"
                                                            checked={isItemSelected}
                                                            inputProps={{
                                                                'aria-labelledby': labelId,
                                                            }}
                                                        />
                                                    </TableCell>}

                                                    {headCells?.map(cell => {
                                                        const keys = cell['id'].split('.')
                                                        let value = Boolean(cell?.getValue) ? cell.getValue(row) : keys.reduce((oldValue, item) => (oldValue[item] || '-'), row)
                                                        return (
                                                            <TableCell key={cell['id']} align="left">{value || '-'}</TableCell>
                                                        )
                                                    })}
                                                </TableRow>
                                            );
                                        })
                                    :
                                    <TableLoading colSpan={(headCells?.length + 1 || 1)} />
                            }
                            {/* {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={(headCells?.length + 1 || 1)} />
                                </TableRow>
                            )} */}


                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 25, 50, 100, 200, 400, 800, { label: 'All', value: '' }]}
                    component="div"
                    count={pagination.total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            {/* <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            /> */}
        </Box>
    );
}

export default AppTable;