import { Button } from "@mui/material";
import { useState, useEffect, useContext, useRef } from "react";
import http from "../../http";
import { baseUrl, getApiRoute } from "../../utils/api-routes";
import AppTable from "../Table";
import Form from "./form";
import Filter from "../Agent/filter";
import ExportProduct from "../Product/export";
import ExportOrder from "../Order/export";
import LanguageContext from "../../context/LanguageContext";
import { FormControl, InputLabel, MenuItem, Select, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";


function CRUD({ title, cells, route, titleSingle, fields, beforeTable, onlyShow, customParams }) {
    const { getString } = useContext(LanguageContext)
    const [mode, setMode] = useState('index')
    const [params, setParams] = useState({})
    const [filterFields, setFilterFields] = useState({
        products: [],
        buy: '',
        date_from: '',
        date_to: '',
    })
    const [id, setId] = useState(0)
    const [viewId, setViewId] = useState(0)
    const [agentId, setAgentId] = useState(0)
    const [agentNumber, setAgentNumbers] = useState()
    const [rows, setRows] = useState()
    const [paginate, setPaginate] = useState(5)
    const [filterParams, setFilterParams] = useState({})

    const [pagination, setPagination] = useState({
        total: 0,
        rowsPerPage: 5,
        page: 1,
        nextPage: '',
        pervPage: '',
    })

    const handleChangeRowsPerPage = (event) => {
        setPaginate(parseInt(event.target.value));
        setPagination({ ...pagination, page: 0, rowsPerPage: parseInt(event.target.value) });
    };

    const handleChangePage = async (event, newPage) => {
        const url = newPage + 1 > pagination.page ? pagination.nextPage : pagination.pervPage
        await getData(url)
    };

    const getData = async (url) => {
        try {
            const isFiltered = Boolean(filterParams) && Object.keys(filterParams).length > 0
            const pars = url ? { paginate, ...params } : { paginate, ...params }
            const URL = url ? url : getApiRoute([route, isFiltered ? 'search' : 'get'])
            const response = !isFiltered ? await http.get(URL, pars) : await http.post(URL, { search: filterParams }, pars)
            setPagination({
                total: response.total,
                nextPage: response.next_page_url,
                pervPage: response.prev_page_url,
                page: response.current_page,
                rowsPerPage: response.per_page,
            })
            setPaginate(response.per_page)
            setRows(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    const onDeleteHandler = async (selected) => {
        try {
            const url = getApiRoute([route, 'delete'], { id: selected.join('-') })
            const response = await http.delete(url)
            await getData()
        } catch (error) {
            console.log(error)
        }
    }

    const onMessageHandler = async (selected) => {
        console.log(selected)
        setOpen(true);
        try {
            // const url = getApiRoute([route, 'getIds'], { id: selected.join('-') })
            fetch(`${baseUrl}` + '/getIds?ids=' + selected.join('-')).then(c => c.json()).then(r => setAgentNumbers(r))
            // console.log(response)
        } catch (error) {
            console.log(error)
        }
    }
    const onExportOrderHandler = async (selected) => {
        // window.location.href = `${baseUrl}/exportOrderIds?ids=${selected.join(' - ')}`
        fetch(`${baseUrl}` + '/exportOrderIds?ids=' + selected.join('-'), { method: 'POST' })
            .then(response => response.blob())
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = "Orders.xlsx";
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();  //afterwards we remove the element again         
            });
    }
    const onExportProductHandler = async (selected) => {
        fetch(`${baseUrl}` + '/exportProductIds?ids=' + selected.join('-'), { method: 'POST' })
            .then(response => response.blob())
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = "Products.xlsx";
                document.body.appendChild(a);
                a.click();
                a.remove();
            });
    }
    const onExportAgentHandler = async (selected) => {
        fetch(`${baseUrl}` + '/exportAgentIds?ids=' + selected.join('-'), { method: 'POST' })
            .then(response => response.blob())
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = "Agents.xlsx";
                document.body.appendChild(a);
                a.click();
                a.remove();
            });
    }

    const onEditHandler = async (selected) => {
        console.log(selected)
        setId(selected[0])
    }

    const onViewHandler = async (selected) => {
        console.log(selected)
        setViewId(selected[0])
    }


    const [open, setOpen] = useState(false);

    // const handleClickOpen = () => {
    //     setOpen(true);
    // };

    const handleClose = () => {
        setOpen(false);
    };

    const WAA_Message = localStorage.getItem('wa_message');
    const WAA_url = localStorage.getItem('wa_url');
    const handleSend = async () => {
        const data = wa_message.current.value
        const data2 = wa_url.current.value
        localStorage.setItem('wa_message', data)
        localStorage.setItem('wa_url', data2)
        console.log(data);
        console.log('phone', agentNumber);

        if (agentNumber.length > 0) {
            agentNumber.forEach(function (number) {

                fetch('https://wa-toolbox.web.app/mirror', {
                    method: 'POST',
                    body: JSON.stringify({
                        data: {
                            action: "send-template",
                            template: data,
                            phone: number,
                        }, webhook: { url: data2 }, method: 'POST'
                    })
                }).then(res => handleClose());

            });
        }


    }

    const wa_message = useRef(null);
    const wa_url = useRef(null);
    // const onSendingMessageHndler = async (selected) => {
    //     setOpen(true);
    //     setAgentId(selected[0])
    //     const response = await http.get(getApiRoute([route, 'get']))
    //     const agentData = response.data.filter(d => d.id == agentId)
    //     const agentPhone = agentData[0].phone
    //     setAgentNumber(agentData[0].phone)
    //     console.log('response', agentPhone)
    //     console.log('response.data', response.data)
    //     console.log('agentId', agentId)
    //     // if (selected.length != 0) {
    //     //     console.log('data', selected)
    //     // }
    //     // return selected;

    // }



    const create = () => {
        setMode('create')
    }

    const changeMode = (mode) => {
        setMode(mode)
    }

    const handleChange = (event, key) => {
        setFilterFields(old => {
            return { ...old, [key]: event.target.value }
        })
    }

    useEffect(async () => {
        if (Boolean(customParams)) {
            setParams(old => {
                return { ...old, ...customParams }
            })
        }
    }, [])
    useEffect(async () => {
        await getData()
    }, [paginate, params, filterParams])

    useEffect(async () => {
        if (id && id != 0) {

            setMode('edit')

        }
    }, [id])

    useEffect(async () => {
        if (viewId && viewId != 0) {

            setMode('view')

        }
    }, [viewId])

    const handleSubmitFilter = async () => {
        setFilterFields(old => {
            return { ...old, filter: 1 }
        })
        setParams(old => {
            return { ...old, ...filterFields }
        })
    }

    const clearFilter = () => {
        setFilterFields({
            products: [],
            buy: '',
            date_from: '',
            date_to: '',
        })
        setParams({})
    }


    return (
        <>
            {
                mode == 'index' &&
                <>
                    {
                        !Boolean(onlyShow) &&
                        <div style={{ display: `flex`, justifyContent: 'flex-end', padding: '20px 0' }}>
                            <Button variant="contained"
                                onClick={create}
                            >{getString(`Create`) + " " + getString(titleSingle)}</Button>
                        </div>
                    }
                    {beforeTable}
                    {route == 'agents' && <Filter
                        handleProductsChange={handleChange}
                        filterFields={filterFields}
                        handleChange={handleChange}
                        clearFilter={clearFilter}
                        handleSubmitFilter={handleSubmitFilter}
                    />}
                    {/* {route == 'products' && < ExportProduct />}
                    {route == 'orders' && < ExportOrder />} */}
                    <Dialog open={open} onClose={handleClose} fullWidth sx={{ m: 1 }}>
                        <DialogTitle>Your Message </DialogTitle>
                        <DialogContent >
                            {/* <DialogContentText>
                            To subscribe to this website, please enter your email address here. We
                            will send updates occasionally.
                        </DialogContentText> */}
                            <TextField
                                fullWidth
                                autoFocus
                                multiline
                                margin="dense"
                                id="message"
                                label="Your Message"
                                variant="standard"
                                inputRef={wa_message}
                                defaultValue={WAA_Message}
                            // inputRef={WA_Message}
                            />
                            <TextField
                                fullWidth
                                autoFocus
                                type="url"
                                margin="dense"
                                id="webhook"
                                label="Your Webhook URL"
                                variant="standard"
                                inputRef={wa_url}
                                defaultValue={WAA_url}
                            // inputRef={WA_Message}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button onClick={handleSend}>Send</Button>
                        </DialogActions>
                    </Dialog>
                    <AppTable
                        rows={rows}
                        getData={getData}
                        filterParams={filterParams}
                        setFilterParams={setFilterParams}
                        onDeleteHandler={onDeleteHandler}
                        onEdit={onEditHandler}
                        onView={onViewHandler}
                        onSendingMessage={onMessageHandler}
                        onExportingOrder={onExportOrderHandler}
                        onExportingProduct={onExportProductHandler}
                        onExportingAgent={onExportAgentHandler}
                        // onSendingMessage={onSendingMessageHndler}
                        title={getString(title)}
                        titleSingle={getString(titleSingle)}
                        page={pagination.page}
                        rowsPerPage={pagination.rowsPerPage}
                        pagination={pagination}
                        route={route}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        headCells={cells}
                        onlyShow={onlyShow}
                    />
                </>
            }
            {
                (mode == 'create' || mode == 'edit' || mode == 'view') &&
                <Form title={titleSingle} fields={fields} route={route} mode={mode} id={mode == 'view' ? viewId : id} changeMode={changeMode} setViewId={setViewId} getData={getData} setId={setId} />
            }
        </>
    )
}

export default CRUD;