import { useState, useEffect, useContext } from "react";
import CRUD from "../CRUD";
import { areas, category, strength } from "../../config";
import LanguageContext from "../../context/LanguageContext";


function Agent() {

    // const onClickHandler = () => console.log(areas.map(area => <li>{area.dist_name}</li>).join(', '));

    const [city, setCity] = useState('');
    const { getString } = useContext(LanguageContext)
    const [cells, setCells] = useState([
        {
            id: 'name',
            numeric: false,
            disablePadding: false,
            label: 'Name',
        },
        {
            id: 'phone',
            numeric: false,
            disablePadding: false,
            label: 'Phone',
        },
        {
            id: 'city',
            required: true,
            label: 'City',
            type: 'select',
        },
        {
            id: 'district',
            required: true,
            label: 'District',
            type: 'select',
        },
        {
            id: 'category',
            required: true,
            label: 'Category',
            type: 'select',
        },
        {
            id: 'strength',
            required: true,
            label: 'Strength',
            type: 'select',
        },
        {
            id: 'location',
            numeric: true,
            disablePadding: false,
            label: 'Location',
        },
        {
            id: 'duration_number',
            numeric: true,
            disablePadding: false,
            label: 'Duration Number',
        }
    ])


    const f = [
        {
            id: 'name',
            required: true,
            label: 'Name',
            type: 'text'
        },
        {
            id: 'phone',
            required: true,
            label: 'Phone',
            type: 'text'
        },
        {
            id: 'city',
            required: true,
            label: 'City',
            type: 'select2',
            options: Object.keys(areas).map(function (c) {
                return { 'label': c, 'value': c };
            }),
            onChange: (e) => { setCity(e.target.value); }
        },
        {
            id: 'district',
            required: true,
            disablePadding: false,
            label: 'District',
            type: 'select',
            options: city ? areas[city].map(function (c) {
                return { 'label': c, 'value': c };
            }) : [],
        },
        {
            id: 'category',
            required: true,
            disablePadding: false,
            label: 'Category',
            type: 'select',
            options: Object.keys(category).map(function (c) {
                return { 'label': getString(c), 'value': c };
            }),
        },
        {
            id: 'strength',
            required: true,
            disablePadding: false,
            label: 'Strength',
            type: 'select',
            options: Object.keys(strength).map(function (c) {
                return { 'label': getString(c), 'value': c };
            }),
        },
        {
            id: 'location',
            required: true,
            label: 'Location',
            type: 'google-autocomplete',
        },
        {
            id: 'duration_number',
            numeric: true,
            disablePadding: false,
            label: 'Duration Number',
        }


    ]


    useEffect(() => {
        setCity(city);
        setFields(f);
    }, [city])


    const [fields, setFields] = useState(f)


    // const [products, setProducts] = useState([]);
    // const [date, setDate] = useState('');
    // const [buy, setBuy] = useState('');

    // const handleProductsChange = (event) => {
    //     setProducts(event.target.value);
    // };
    // const handleDateChange = (event) => {
    //     setDate(event.target.value);
    // };
    // const handleBuyChange = (event) => {
    //     setBuy(event.target.value);
    // };

    return (
        <CRUD
            cells={cells}
            title={`Agents`}
            titleSingle={`Agent`}
            route={`agents`}
            fields={fields}
        />
    );
}
export default Agent;