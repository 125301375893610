import { Box } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { mapApiKey } from "../../config";
import AuthContext from "../../context/AuthContext";
import AppDrawer from "../Drawer";
import Header from "../Header";
import Main from "../Main";
import LoginLayout from "./LoginLayout";


export const useScript = url => {
    useEffect(() => {
        const script = document.createElement('script');

        script.src = url;
        // script.async = true;

        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        }
    }, [url])
}


function Layout({ children }) {

    const [countNotify, setCountNotify] = useState(0);
    const { user } = useContext(AuthContext);
    const [open, setOpen] = useState(true);
    const [drawerWidth, _set] = useState(240);
    const [headerHeight, _s] = useState(70);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };


    if (!user) {
        return (
            <LoginLayout>
                {children}
            </LoginLayout>
        );
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <Header handleDrawerOpen={handleDrawerOpen} open={open} drawerWidth={drawerWidth} headerHeight={headerHeight} notifications={countNotify} />
            <AppDrawer open={open} drawerWidth={drawerWidth} headerHeight={headerHeight} />
            <Main open={open} drawerWidth={drawerWidth} headerHeight={headerHeight}>
                {children}
            </Main>
        </Box>
    );
}

export default Layout;