import { alpha, Toolbar, Tooltip, Typography } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useLocation } from 'react-router-dom';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

function AppTableToolbar({ numSelected, title, onDelete, onEdit, onView, onlyShow, onSendingMessage, onExportingAgent, onExportingProduct, onExportingOrder, route, selected }) {
    const location = useLocation();
    // const handleSend = async (selected) => {
    //     console.log('hello from export')
    //     console.log(selected)
    //     // if (agentNumber.length > 0) {
    //     fetch('https://wa-toolbox.web.app/mirror', {
    //         method: 'post',
    //         body: JSON.stringify({
    //             data: {
    //                 action: "send-template",
    //                 template: 'data',
    //                 phone: 'number',
    //             }, webhook: { url: "data2" }, method: 'POST'
    //         })
    //     })
    //     // }


    // }

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    {title}
                </Typography>
            )}

            {(!Boolean(onlyShow) && numSelected > 0) && (
                <>
                    <Tooltip title="Delete">
                        <IconButton onClick={onDelete}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                    {numSelected === 1 &&
                        <Tooltip title="Edit">
                            <IconButton onClick={onEdit}>
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    }
                    {numSelected === 1 &&
                        <Tooltip title="View">
                            <IconButton onClick={onView}>
                                <VisibilityIcon />
                            </IconButton>
                        </Tooltip>
                    }

                    {location.pathname === '/agents' &&
                        <Tooltip title="Send WhatsApp Message">
                            <IconButton
                                onClick={onSendingMessage}
                            >
                                <WhatsAppIcon sx={{ color: '#4CAF50' }} />
                            </IconButton>
                        </Tooltip>}
                    {location.pathname === '/agents' &&
                        <Tooltip title="Export Details">
                            <IconButton
                                // onClick={onExportingMessage}
                                onClick={onExportingAgent}
                            >
                                <FileDownloadIcon sx={{ color: '#1565C0' }} />
                            </IconButton>
                        </Tooltip>}
                    {location.pathname === '/products' &&
                        <Tooltip title="Export Details">
                            <IconButton
                                // onClick={onExportingMessage}
                                onClick={onExportingProduct}
                            >
                                <FileDownloadIcon sx={{ color: '#1565C0' }} />
                            </IconButton>
                        </Tooltip>}
                    {location.pathname === '/orders' &&
                        <Tooltip title="Export Details">
                            <IconButton
                                // onClick={onExportingMessage}
                                onClick={onExportingOrder}
                            >
                                <FileDownloadIcon sx={{ color: '#1565C0' }} />
                            </IconButton>
                        </Tooltip>}

                </>
            )}
        </Toolbar>

    )
}

export default AppTableToolbar;