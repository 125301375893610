import { useState, useEffect, useContext } from "react";
import CRUD from "../CRUD";
import LanguageContext from "../../context/LanguageContext";

function Branch() {
    const { getString } = useContext(LanguageContext)
    const [cells, setCells] = useState([
        {
            id: 'name',
            numeric: false,
            disablePadding: false,
            label: 'Name',
        },
        {
            id: 'city',
            numeric: false,
            disablePadding: false,
            label: 'City',
        },
        {
            id: 'phone',
            numeric: true,
            disablePadding: false,
            label: 'Phone',
        }
    ])

    const [fields, setFields] = useState([
        {
            id: 'name',
            required: true,
            label: 'Name',
            type: 'text'
        },
        {
            id: 'city',
            required: true,
            label: 'City',
            type: 'text'
        },
        {
            id: 'phone',
            required: true,
            label: 'Phone',
            type: 'tel'
        },
        {
            id: 'address',
            required: true,
            label: 'Address',
            type: 'text',
        },

    ])


    return (
        <CRUD
            cells={cells}
            title={`Branches`}
            titleSingle={`Branch`}
            route={`branches`}
            fields={fields}
        />
    );
}
export default Branch;