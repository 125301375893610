import AppRoutes from "./components/AppRoutes";
import Layout from "./components/Layout";
import { AuthContextProvider } from "./context/AuthContext";
import { LanguageContextProvider } from "./context/LanguageContext";
import { NotificationsContextProvider } from "./context/NotificationsContext";


function App() {
  return (
    <AuthContextProvider>
      <LanguageContextProvider>
        <NotificationsContextProvider>
          <Layout>
            <AppRoutes />
          </Layout>
        </NotificationsContextProvider>
      </LanguageContextProvider>
    </AuthContextProvider>
  );
}

export default App;
