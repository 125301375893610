import { useState, useEffect } from "react";
import CRUD from "../CRUD";

function Order({ onlyShow, agent_id }) {

    const [cells, setCells] = useState([
        {
            id: 'products',
            filterType: 'relation',
            relationField: 'name',
            numeric: true,
            disablePadding: false,
            label: 'Products Names',
            getValue: row => {
                return row.products?.map(product => product.name)?.join(', ') || '-'
            }
        },
        {
            id: 'agent.name',
            relationField: 'name',
            numeric: true,
            disablePadding: false,
            filterType: 'relation',
            label: 'Agent'
        },
        {
            id: 'quantity',
            numeric: true,
            disablePadding: false,
            filterType: 'field',
            label: 'Quantity'
        },
        {
            id: 'date',
            numeric: true,
            disablePadding: false,
            filterType: 'field',
            label: 'Date'
        },
    ])

    const [fields, setFields] = useState([
        {
            id: 'products',
            required: true,
            label: 'Products',
            multiple: true,
            type: 'select',
            options: 'products'
        },
        {
            id: 'agent_id',
            required: true,
            label: 'Agent',
            type: 'select',
            multiple: false,
            options: 'agents'
        },
        {
            id: 'quantity',
            required: true,
            label: 'Quantity',
            type: 'number'
        },
        {
            id: 'date',
            required: true,
            label: 'Date',
            type: 'date'
        },
    ])


    return (
        <CRUD
            cells={cells}
            title={`Orders`}
            titleSingle={`Order`}
            route={`orders`}
            fields={fields}
            onlyShow={onlyShow}
            customParams={{ ...(Boolean(agent_id) && { agent_id }) }}
        />
    );
}

export default Order;  