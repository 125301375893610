import { createContext, useEffect, useState } from 'react';
import http from '../http';
import { apiRoutes } from '../utils/api-routes';

const AuthContext = createContext({
    user: null,
    login: function (email, password) { },
    logout: function () { },
});

export function AuthContextProvider(props) {
    const [user, setUser] = useState();

    function loginHandler(email, password) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await http.post(apiRoutes.users.login, { email, password })
                localStorage.setItem('api_token', response?.api_token)
                setUser(response);
                resolve(true)
            } catch (error) {
                reject(error)
            }
        })
    }

    function logoutHandler() {
        return new Promise(async (resolve, reject) => {
            try {
                setUser(null);
                localStorage.removeItem('api_token')
                resolve(true);
            } catch (error) {
                reject(error)
            }
        })
    }

    const context = {
        user,
        login: loginHandler,
        logout: logoutHandler,
    };

    useEffect(async () => {
        const oldToken = localStorage.getItem('api_token')
        if (oldToken) {
            try {
                const response = await http.get(apiRoutes.users.user)
                setUser({ ...response, api_token: oldToken })
            }
            catch (error) {

            }
        }

    }, [])


    return (
        <AuthContext.Provider value={context}>
            {props.children}
        </AuthContext.Provider>
    );
}

export default AuthContext;