import axios from 'axios'


const newRequest = (method, url, data, params) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axios({
                method,
                url,
                params,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('api_token')}`
                },
                data
            })
            if (response.data?.success) {
                resolve(response.data.data)
            } else {
                throw ({ response })
            }

        } catch (error) {
            const message = error.response?.data?.message || 'Something went wrong!'
            const status = error.response?.status || 0
            reject({
                message,
                status,
                url,
                data
            })
        }
    })
}


class Http {

    async get(url, params) {
        return newRequest('get', url, {}, params)
    }

    async post(url, data, params) {
        return newRequest('post', url, data, params)
    }

    async put(url, data, params) {
        return newRequest('put', url, data, params)
    }

    async delete(url, params) {
        return newRequest('delete', url, {}, params)
    }


}


// function SWRCon({ children }) {
//     const fetcher = (...args) => fetch(...args).then(response => response.json())
//     return <SWRConfig
//         value={{
//             // refreshInterval: 3000,
//             fetcher
//         }}
//     >
//         {children}
//     </SWRConfig>
// }


// export { SWRCon }

export default new Http()