import { version } from "../../config";
import Elbruz from "../Elbruz";

function Footer() {
    return (
        <div style={{ position: 'fixed', bottom: '0px', width: '100%', display: 'flex', justifyContent: 'space-between', background: '#fff' }}>
            <h4 style={{ color: '#ccc' }}>v{version}</h4>
            <Elbruz color={`#000`} style={{ height: '20px', fontSize: '15px', marginRight: '20rem' }} />
        </div>
    )
}

export default Footer;