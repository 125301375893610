import { Badge, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";

function Dropdown({ handleOpen, open, handleClose, label, items, tooltip, style, badge }) {
    return (
        <>
            <Tooltip title={tooltip}>
                <IconButton
                    onClick={handleOpen}
                    size="large"
                    aria-label="show 17 new notifications"
                    color="inherit"
                    style={style}
                >

                    <Badge badgeContent={badge} color="error" >
                        {label}
                    </Badge>

                </IconButton>
            </Tooltip>
            <Menu
                sx={{ mt: '45px' }}
                id="menu-notifications"
                anchorEl={open}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(open)}
                onClose={handleClose}
            >
                {
                    items?.map((item, index) => (
                        <MenuItem key={index} onClick={() => {
                            item.onClick && item.onClick(item)
                            handleClose()
                        }}>
                            <Typography textAlign="center">{item.label}</Typography>
                        </MenuItem>
                    ))
                }
            </Menu>
        </>
    );
}

export default Dropdown;