import { Checkbox, Input, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { Box } from "@mui/system";
import { visuallyHidden } from '@mui/utils';
import LanguageContext from "../../context/LanguageContext";
import { useContext } from "react";

function AppTableHead({ onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells, columnSearchHandle, onlyShow }) {

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    const { getString } = useContext(LanguageContext)


    return (
        <TableHead>
            <TableRow>
                {!Boolean(onlyShow) && <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>}
                {headCells?.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        // align={headCell.numeric ? 'right' : 'left'}
                        align={`left`}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {getString(headCell.label)}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                        <div>
                            <Input type={`text`} placeholder={getString('Search...')} onChange={event => columnSearchHandle(event, headCell)} />
                        </div>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

export default AppTableHead;