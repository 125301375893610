import GoogleMapReact from 'google-map-react';
import { useEffect, useState } from 'react';
import { mapApiKey as key } from '../../config'
import http from '../../http';
import { baseUrl, getApiRoute } from '../../utils/api-routes';
import {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';


function Map() {
    const [agents, setAgents] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [loading, setLoading] = useState(false)

    const [center, setCenter] = useState({
        lat: 59.95,
        lng: 30.33
    })


    const zoom = 13


    useEffect(async () => {
        const response = await http.get(getApiRoute(['agents', 'get']), { all: 1 })
        const agentsPromises = response?.map(async (agent) => {
            // const results = await fetch("https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyCAJZuwGHYJW-tyD9XV1HqaBG5KFn3DndY&address=" + agent?.location);
            // const response = await results.json();
            // // console.warn(response);
            // const geo = response.results?.length > 0 ? response.results[0].geometry.location : { lat: 0, lng: 0 };
            const geo =  { lat: agent.lat, lng: agent.lng };
            return {
                ...agent,
                geo
            }
        })


        Promise.all(agentsPromises).then(res => {
            setLoading(false)
            setAgents(res)
            setCenter(res[0].geo)


            // ========

            var locations = res.map(a => [a.name, a.geo.lat, a.geo.lng])
            console.log(locations);

            var map = new window.google.maps.Map(document.getElementById('map'), {
                zoom: 10,
                center: new window.google.maps.LatLng(res[0].geo.lat, res[0].geo.lng),
                mapTypeId: window.google.maps.MapTypeId.ROADMAP
            });

            var infowindow = new window.google.maps.InfoWindow();

            var marker, i;

            for (i = 0; i < locations.length; i++) {
                marker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(locations[i][1], locations[i][2]),
                    map: map
                });

                window.google.maps.event.addListener(marker, 'click', (function (marker, i) {
                    return function () {
                        infowindow.setContent(locations[i][0]);
                        infowindow.open(map, marker);
                    }
                })(marker, i));
            }
        })
    }, [])
    return (
        <div id="map">
        </div>
    );
}

export default Map;