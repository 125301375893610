import DashboardIcon from '@mui/icons-material/Dashboard'
import PersonIcon from '@mui/icons-material/Person'
import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import CategoryIcon from '@mui/icons-material/Category';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import Dashboard from "../components/Dashboard"
import User from "../components/User"
import Agent from "../components/Agent"
import Product from "../components/Product"
import Branch from "../components/Branch";
import Login from "../components/Login";
import Category from "../components/Category";
import Size from "../components/Size";
import Order from "../components/Order";

const routes = [
    {
        path: '/',
        element: <Login />
    },
    {
        path: '/login',
        element: <Login />
    },
    {
        path: '/dashboard',
        element: <Dashboard />,
        label: 'dashboard',
        icon: <DashboardIcon />,
        protected: true
    },
    {
        path: '/orders',
        element: <Order />,
        label: 'orders',
        icon: <LocalShippingIcon />,
        protected: true
    },
    {
        path: '/agents',
        element: <Agent />,
        label: 'agents',
        icon: <SupportAgentIcon />,
        protected: true
    },
    {
        path: '/products',
        element: <Product />,
        label: 'products',
        icon: <ProductionQuantityLimitsIcon />,
        protected: true
    },
    {
        path: '/categories',
        element: <Category />,
        label: 'categories',
        icon: <CategoryIcon />,
        protected: true
    },
    {
        path: '/sizes',
        element: <Size />,
        label: 'sizes',
        icon: <AspectRatioIcon />,
        protected: true
    },
    {
        path: '/branches',
        element: <Branch />,
        label: 'branches',
        icon: <CompareArrowsIcon />,
        protected: true
    },
    {
        path: '/users',
        element: <User />,
        label: 'users',
        icon: <PersonIcon />,
        protected: true
    },
]

export { routes }