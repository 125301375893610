import { useState, useEffect } from "react";
import CRUD from "../CRUD";

// function User() {

//     const [users, setUsers] = useState()
//     const [paginate, setPaginate] = useState(5)

//     const [pagination, setPagination] = useState({
//         total: 0,
//         rowsPerPage: 5,
//         page: 1,
//         nextPage: '',
//         pervPage: '',
//     })

//     const [cells, setCells] = useState([
//         {
//             id: 'name',
//             numeric: false,
//             disablePadding: false,
//             label: 'Name',
//         },
//         {
//             id: 'branch.name',
//             numeric: false,
//             disablePadding: false,
//             label: 'Branch',
//         },
//         {
//             id: 'role',
//             numeric: true,
//             disablePadding: false,
//             label: 'Role',
//         }
//     ])

//     const handleChangeRowsPerPage = (event) => {
//         setPaginate(parseInt(event.target.value));
//         setPagination({ ...pagination, page: 0, rowsPerPage: parseInt(event.target.value) });
//     };

//     const handleChangePage = async (event, newPage) => {
//         const url = newPage + 1 > pagination.page ? pagination.nextPage : pagination.pervPage
//         await getData(url)
//     };

//     const getData = async (url) => {
//         try {
//             const params = url ? { paginate } : { paginate }
//             const URL = url ? url : getApiRoute(['users', 'get'])
//             const response = await http.get(URL, params)
//             setPagination({
//                 total: response.total,
//                 nextPage: response.next_page_url,
//                 pervPage: response.prev_page_url,
//                 page: response.current_page,
//                 rowsPerPage: response.per_page,
//             })
//             setPaginate(response.per_page)
//             setUsers(response.data)
//         } catch (error) {
//             console.log(error)
//         }
//     }

//     const onDeleteHandler = async (selected) => {
//         try {
//             const url = getApiRoute(['users', 'delete'], { id: selected.join('-') })
//             const response = await http.delete(url)
//             await getData()
//         } catch (error) {
//             console.log(error)
//         }
//     }

//     useEffect(async () => {
//         await getData()
//     }, [paginate])


//     return (
//         <>
//             <AppTable
//                 rows={users}
//                 onDeleteHandler={onDeleteHandler}
//                 title={`Users`}
//                 page={pagination.page}
//                 rowsPerPage={pagination.rowsPerPage}
//                 pagination={pagination}
//                 handleChangePage={handleChangePage}
//                 handleChangeRowsPerPage={handleChangeRowsPerPage}
//                 headCells={cells}
//             />
//         </>
//     );
// }

function User() {

    const [cells, setCells] = useState([
        {
            id: 'name',
            numeric: false,
            disablePadding: false,
            label: 'Name',
        },
        {
            id: 'branch.name',
            numeric: false,
            disablePadding: false,
            filterType: 'relation',
            relationField: 'name',
            label: 'Branch',
        },
        {
            id: 'role',
            numeric: true,
            disablePadding: false,
            label: 'Role',
        }
    ])

    const [fields, setFields] = useState([
        {
            id: 'name',
            required: true,
            label: 'Name',
            type: 'text'
        },
        {
            id: 'role',
            required: true,
            label: 'Role',
            type: 'select',
            options: [
                {
                    label: 'Admin',
                    value: 'admin'
                },
                {
                    label: 'Viewer',
                    value: 'viewer'
                },
            ]
        },
        {
            id: 'email',
            required: true,
            label: 'Email',
            type: 'email'
        },
        {
            id: 'phone',
            required: true,
            label: 'Phone',
            type: 'tel'
        },
        {
            id: 'branch_id',
            required: true,
            label: 'Branch',
            type: 'select',
            options: 'branches'
        },
        {
            id: 'password',
            required: true,
            label: 'Password',
            type: 'password',
        },

    ])


    return (
        <CRUD
            cells={cells}
            title={`Users`}
            titleSingle={`User`}
            route={`users`}
            fields={fields}
        />
    );
}
export default User;