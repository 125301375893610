import Footer from "../Footer";
import React from 'react';

const Main = ({ children, open, drawerWidth, headerHeight }) => {
    return (
        <main style={{ ...(!Boolean(open) && { marginLeft: `-${drawerWidth}px` }), marginTop: `${headerHeight}px`, padding: `10px`, width: '100%', minHeight: '70vh' }}>
            {children}
            <div style={{ paddingBottom: '4rem' }}></div>
            <Footer />
        </main>
    );
    
}

export default Main;