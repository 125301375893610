

export const AppStrings = {
    ar: {
        dashboard: 'لوحة التحكم',
        orders: 'الطلبات',
        Orders: 'الطلبات',
        agents: 'الوكلاء',
        Agent: 'وكيل',
        Agents: 'الوكلاء',
        products: 'المنتجات',
        Products: 'المنتجات',
        categories: 'التصنيفات',
        sizes: 'القياسات',
        branches: 'الفروع',
        users: 'المستخدمين',
        "Products Names": 'أسماء المنتجات',

        Quantity: 'الكمية',
        Date: 'التاريخ',

        Name: 'الاسم',
        Phone: 'الهاتف',
        Location: 'الموقع',
        // submit: '÷رسال',
        Image: 'الصورة',
        Category: 'تصنيف',
        Size: 'قياس',
        "Total Products": 'مجموع المنتجات',
        Categories: 'التصنيفات',
        Sizes: 'القياسات',
        Branches: 'الفروع',
        City: 'المدينة',
        Branch: 'فرع',
        Role: 'الوظيفة',
        Users: 'المستخدمين',
        'All Users': 'جميع المستخدمين',
        'All Orders': 'جميع الطلبات',
        'All Products': 'جميع المنتجات',
        'All Agents': 'جميع العملاء',
        Name: 'الاسم',
        Create: 'إنشاء',
        Address: 'العنوان',
        // Location: 'العنوان',
        Description: 'الوصف',
        'Search...': 'بحث...',

        Submit: 'إرسال',
        'Go Back': 'الرجوع للخلف',
        Email: 'الإيميل',
        Password: 'كلمة المرور',
        User: 'مستخدم',
        Order: 'طلب',
        Product: 'منتج',
        'Duration Number': "المدة",
        Clear: 'مسح',
        Buy: 'شراء',
        'Date To': 'من تاريخ',
        'Date From': 'إلى تاريخ',
        Filter: 'تصفية',
        'Yes': 'نعم',
        'yes': 'نعم',
        'No': 'لا',
        'no': 'لا',
        'Agent "': '  لم يطلب من مدة " ',
        // 'Agent "': ,
        // '" has not purchased in a while.': ' " لم يطلب من مدة',
        '" has not purchased in a while.': ' " الوكيل ',
        District: 'المنطقة',
        'Export': 'تصدير ',
        Strong: 'قوي',
        Moderate: 'متوسط',
        Weak: 'ضعيف',
        Resturants: 'مطاعم',
        Market: 'متجر',
        Supermarket: 'سوبر ماركت',
        Wholesale: 'بالجملة',
        'Wholesale of wholesale': 'جملة الجملة',
        Strength: 'القوة',

    },
    en: {
        dashboard: 'Dashboard',
        orders: 'Orders',
        Orders: 'Orders',
        agents: 'Agents',
        Agents: 'Agents',
        Agent: 'Agent',
        products: 'Products',
        Products: 'Products',
        categories: 'Categories',
        sizes: 'Sizes',
        branches: 'Branches',
        users: 'Users',
        "Products Names": 'Products Names',

        Quantity: 'Quantity',
        Date: 'Date',
        Name: 'Name',
        Phone: 'Phone',
        Location: 'Location',
        Image: 'Image',
        Category: 'Category',
        Size: 'Size',
        "Total Products": 'Total Products',
        Categories: 'Categories',
        Sizes: 'Sizes',
        Branches: 'Branches',
        City: 'City',
        Branch: 'Branch',
        Role: 'Role',
        Users: 'Users',
        'All Users': 'All Users',
        'All Orders': 'All Orders',
        'All Products': 'All Products',
        'All Agents': 'All Agents',
        Name: 'Name',
        Create: 'Create',
        Address: 'Adress',
        Description: 'Description',
        'Search...': 'Search...',

        Submit: 'Submit',
        'Go Back': 'Go Back',
        Email: 'Email',
        Password: 'Password',
        User: 'User',
        Order: 'Order',
        Product: 'Product',
        'Duration Number': 'Duration Number',
        'Clear': 'Clear',
        'Buy': 'Buy',
        'Date To': 'Date To',
        'Date From': 'Date From',
        'Filter': 'Filter',
        'Yes': 'Yes',
        'No': 'No',
        'Agent "': '  Agent " ',
        '" has not purchased in a while.': '" has not purchased in a while.',
        District: 'District',
        'Export': 'Export',
        Strong: 'Strong',
        Moderate: 'Moderate',
        Weak: 'Weak',
        Resturants: 'Resturants',
        Market: 'Market',
        Supermarket: 'Supermarket',
        Export: 'Export',
        Wholesale: 'Wholesale',
        'Wholesale of wholesale': 'Wholesale of wholesale',
        Strength: 'Strength',
    }
}
