import { useState } from "react";
import { fileToBase64 } from "../../helpers/files";
import CRUD from "../CRUD";

function Product() {
    const [cells, setCells] = useState([
        {
            id: 'image',
            numeric: false,
            disablePadding: false,
            label: 'Image',
            getValue: (row) => {
                return <img src={row.image} style={{ width: `100px` }} />
            }
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: false,
            label: 'Name',
        },
        {
            id: 'category.name',
            numeric: false,
            disablePadding: false,
            filterType: 'relation',
            relationField: 'name',
            label: 'Category',
        },
        {
            id: 'sizes',
            numeric: true,
            disablePadding: false,
            label: 'Size',
            getValue: (row) => {
                return row.sizes.map(size => size.name).join(', ')
            }
        }
    ])

    const [fields, setFields] = useState([
        {
            id: 'name',
            required: true,
            label: 'Name',
            type: 'text'
        },
        {
            id: 'image',
            required: true,
            label: 'Image',
            type: 'file'
        },
        {
            id: 'category_id',
            required: true,
            label: 'Category',
            type: 'select',
            options: 'categories'
        },
        {
            id: 'sizes',
            required: true,
            label: 'Size',
            multiple: true,
            type: 'select',
            options: 'sizes'
        },
        {
            id: 'description',
            required: true,
            label: 'Description',
            type: 'text',
        },

    ])


    return (
        <CRUD
            cells={cells}
            title={`Products`}
            titleSingle={`Product`}
            route={`products`}
            fields={fields}
        />
    );
}
export default Product;