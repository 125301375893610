import React, { createContext, useState, useEffect } from 'react'

import { baseUrl } from '../utils/api-routes';

const notificationsList = 0;
export const NotificationsContext = createContext({
    notificationsList: notificationsList,
    getNotificationsList: function () { },
});





export const NotificationsContextProvider = ({ children }) => {
    const [notificationsList, setNotificationsList] = useState([]);
    const [isFirstTime, setIsFirstTime] = useState(true);

    const getNotificationsList = async () => {
        const list = await fetch(`${baseUrl}/noty`)
            .then(results => results.json())
            .then(response => {
                console.warn('resp', response);
                return response;
            });

        setNotificationsList(list);
        setIsFirstTime(false)
    }


    useEffect(async () => {
        console.log('im here');
        if (isFirstTime) getNotificationsList();
    }, [notificationsList, isFirstTime]);


    const context = {
        notificationsList,
        getNotificationsList: getNotificationsList
    };

    return (
        <NotificationsContext.Provider value={context}>
            {children}
        </NotificationsContext.Provider>

    )
}
export default NotificationsContext
