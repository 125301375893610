import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { useState, useEffect, useContext } from 'react';
import AutoComplete from '../CRUD/AutoComplete';
import Map from '../Map';
import Box from './Box';
import LanguageContext from "../../context/LanguageContext";
import { mapApiKey as key } from '../../config'
import http from '../../http';
import { baseUrl } from '../../utils/api-routes';
import '../../App.css';


function Dashboard() {
    const { getString } = useContext(LanguageContext)
    const [agentsCount, setAgentsCount] = useState(0)
    const [ProductsCount, setProductsCount] = useState(0)
    const [OrdersCount, setOrdersCount] = useState(0)
    const [UsersCount, setUsersCount] = useState(0)
    const [loaded, setLoaded] = useState(false)
    const [loading, setLoading] = useState(false)
    // const baseUrl = `http://127.0.0.1:8000/api`

    // const getNotificationsList = async () => {
    //     const list = await fetch('http://127.0.0.1:8000/api/noty')
    //         .then(results => results.json())
    //         .then(response => {
    //             return response;
    //         });

    //     setNotificationsList(list);
    //     setIsFirstTime(false)
    // }
    // const fetchData = () => {
    //     return fetch("http://127.0.0.1:8000/api/agents/count")
    //         .then((response) => response.json())
    //         .then((data) => console.log(data));
    // }
    useEffect(() => {
        fetch(`${baseUrl}/agents/count`)
            .then(results => results.json())
            .then(response => {
                console.log(response.data)
                setAgentsCount(response.data)
            }).catch(error => { console.log(error) });
    }, []);
    useEffect(() => {
        fetch(`${baseUrl}/products/count`)
            .then(results => results.json())
            .then(response => {
                console.log(response.data)
                setProductsCount(response.data)
            }).catch(error => { console.log(error) });
    }, []);
    useEffect(() => {
        fetch(`${baseUrl}/orders/count`)
            .then(results => results.json())
            .then(response => {
                console.log(response.data)
                setOrdersCount(response.data)
            }).catch(error => { console.log(error) });
    }, []);
    useEffect(() => {
        fetch(`${baseUrl}/users/count`)
            .then(results => results.json())
            .then(response => {
                console.log(response.data)
                setUsersCount(response.data)
            }).catch(error => { console.log(error) });
    }, []);

    return (
        <>
            <div>
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: `20px` }}>
                    {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}> */}
                    <Box
                        icon={<PeopleAltIcon />}
                        number={UsersCount}
                        title={getString(`All Users`)}
                    />
                    <Box
                        icon={<PeopleAltIcon />}
                        number={OrdersCount}
                        title={getString(`All Orders`)}
                    />
                    {/* </div> */}
                    {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}> */}
                    <Box
                        icon={<PeopleAltIcon />}
                        number={ProductsCount}
                        title={getString(`All Products`)}
                    />
                    <Box
                        icon={<PeopleAltIcon />}
                        number={agentsCount}
                        title={getString(`All Agents`)}
                    // onClick={console.log(response)}
                    />
                    {/* </div> */}



                </div >

                <Map />
            </div>
        </>
    );
}
export default Dashboard;