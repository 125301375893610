// const baseUrl = `http://127.0.0.1:8000/api`
const baseUrl = `https://ramzy.elbruz.com/api`

const apiRoutes = {
    users: {
        login: `${baseUrl}/users/login`,
        user: `${baseUrl}/users/user`,
        count: `${baseUrl}/users/count`,
        search: `${baseUrl}/users/search?is_search=1`
    },
    orders: {
        count: `${baseUrl}/orders/count`,
        search: `${baseUrl}/orders/search?is_search=1`
    },
    branches: {
        count: `${baseUrl}/branches/count`,
        search: `${baseUrl}/branches/search?is_search=1`
    },
    agents: {
        count: `${baseUrl}/agents/count`,
        filter: `${baseUrl}/agents/filter`,
        search: `${baseUrl}/agents/search?is_search=1`,
        export: `${baseUrl}/export/`
    },
    products: {
        count: `${baseUrl}/products/count`,
        search: `${baseUrl}/products/search?is_search=1`
    },
    categories: {
        count: `${baseUrl}/categories/count`,
        search: `${baseUrl}/categories/search?is_search=1`
    },
    sizes: {
        count: `${baseUrl}/sizes/count`,
        search: `${baseUrl}/sizes/search?is_search=1`
    }
}

Object.keys(apiRoutes).forEach(key => {
    apiRoutes[key] = {
        ...apiRoutes[key],
        get: `${baseUrl}/${key}`,
        post: `${baseUrl}/${key}`,
        show: `${baseUrl}/${key}/(id)`,
        put: `${baseUrl}/${key}/(id)`,
        delete: `${baseUrl}/${key}/(id)`
    }
})

const getApiRoute = (keys = [], vars = {}) => {
    let route = keys.reduce((oldValue, item) => oldValue[item], apiRoutes)
    let variables = route?.match(/\(([^()]*)\)/g) || []
    let variablesKeys = variables?.map((variable) => variable.substring(1, variable.length - 1)) || []
    route = variablesKeys.reduce((oldValue, item, index) => oldValue.replace(variables[index], vars[item]), route)
    return route
}

export { apiRoutes, baseUrl, getApiRoute }