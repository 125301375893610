import { Routes, Route } from "react-router-dom";
import { routes } from "../config/routes";
import RequireAuth from "./Login/RequireAuth";

function AppRoutes() {


    return (
        <Routes>
            {
                routes.map((route, index) => {
                    route.element = Boolean(route.protected) ? <RequireAuth>{route.element}</RequireAuth> : route.element
                    return <Route key={index} path={route.path} element={route.element} />
                })
            }
        </Routes>
    )
}

export default AppRoutes;