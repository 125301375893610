import { Autocomplete, Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { Fragment, useEffect, useState, useContext } from "react";
import http from "../../http";
import { getApiRoute } from "../../utils/api-routes";
import { fileToBase64 } from "../../helpers/files";
import Order from "../Order";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { mapApiKey } from "../../config";
import { useScript } from "../Layout";
import { getSelectionRange } from "@testing-library/user-event/dist/utils";
import LanguageContext from "../../context/LanguageContext";
import NotificationsContext from "../../context/NotificationsContext";





function Form({ title, fields, route, mode, id, changeMode, getData, setId, setViewId }) {
    const { getString } = useContext(LanguageContext)
    const { getNotificationsList } = useContext(NotificationsContext)
    const disabled = mode == 'view'
    const [selectValues, setSelectValues] = useState({})
    const [base64, setBase64] = useState({})
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState('')
    // const [geo, setGeo] = useState('')


    const handleChange = (event, name) => {
        setSelectValues({ ...selectValues, [name]: event.target.value })
    }

    const onSubmit = async (event) => {
        event.preventDefault()

        if (!event.target.checkValidity()) {
            alert('Form not Valid')
            return false
        }

        const formData = new FormData(event.target)
        const data = {}

        for (const index in fields) {
            data[fields[index].id] = formData.get(fields[index].id)
            if (fields[index].type == 'file') {
                data[fields[index].id] = base64[fields[index].id]
                // formData.set(fields[index].id, base64[fields[index].id])
            }
        }

        try {
            const URL = (mode == 'edit' || mode == 'view') ? getApiRoute([route, 'put'], { id }) : getApiRoute([route, 'post'])
            const response = (mode == 'edit' || mode == 'view') ? await http.post(URL, formData) : await http.post(URL, formData)
            console.log(response)
        } catch (error) {
            console.log(error)
        }

        changeMode('index')
        await getData()
        setId(0)
        setViewId(0)

    }
    const sleep = time => {
        return new Promise((resolve) => setTimeout(resolve, time));
    }


    useEffect(async () => {
        for (const index in fields) {
            if (fields[index].type == 'select' || fields[index].type == 'select2') {
                let name = fields[index].id
                if (!fields[index].multiple) {
                    // setSelectValues({ ...selectValues, [name]: '' })
                    setSelectValues(old => {
                        return { ...old, [name]: '' }
                    })
                } else {
                    // setSelectValues({ ...selectValues, [name]: [] })

                    setSelectValues(old => {
                        return { ...old, [name]: [] }
                    })

                }
            }
            if ((fields[index].type == 'select' || fields[index].type == 'select2') && typeof fields[index].options == 'string') {
                const response = await http.get(getApiRoute([fields[index].options, 'get']), { all: 1 })
                fields[index].options = response.map(item => ({ value: item.id, label: item.name }))
            }
        }


        if (mode == 'edit' || mode == 'view') {
            const response = await http.get(getApiRoute([route, 'show'], { id }))
            console.log(response)
            for (const index in fields) {
                if (fields[index].type != 'file') {
                    let name = fields[index].id
                    fields[index].defaultValue = response[name]
                    if (fields[index].type == 'select' || fields[index].type == 'select2') {
                        if (!fields[index].multiple) {
                            setSelectValues((oldValue) => {
                                return { ...oldValue, [name]: response[name] }
                            })
                        } else {
                            // setTimeout(function () {
                            setSelectValues((oldValue) => {
                                return { ...oldValue, [name]: response[name]?.map(item => item.id) }
                            })
                            // }, 2000);
                        }
                    }
                }
            }
        }
        setLoading(true)

        // const script = document.createElement('script');

        // script.src = `https://maps.googleapis.com/maps/api/js?key=${mapApiKey}&libraries=places`;
        // // script.async = true;

        // document.body.appendChild(script);
        return () => {
            // document.body.removeChild(script);
        }
    }, [])

    if (!loading) {
        return <h3>Loading...</h3>
    }

    function refreshPage() {
        setTimeout(function () {
            route === 'agents' || route === 'orders' ? getNotificationsList() : console.log('not order or agent model');
        }, 2000);

    }




    return (
        <>

            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
                autoComplete="off"
                onSubmit={onSubmit}
            >
                <div>
                    <h3>{getString(`Create`) + ' ' + getString(title)}</h3>
                </div>
                <div style={{
                    display: `flex`,
                    flexDirection: `column`,
                    alignItems: `flex-start`,
                }}>
                    {fields?.map((field, index) => {

                        return (
                            <Fragment key={index}>
                                {
                                    (field?.type != 'select' && field?.type != 'select2' && field?.type != 'google-autocomplete') && <TextField
                                        required={field.required}
                                        style={{ margin: `10px 8px` }}
                                        type={field.type}
                                        name={field.id}

                                        disabled={disabled}
                                        id="outlined-required"
                                        label={getString(field.label)}
                                        onChange={async event => {
                                            if (field.type == 'file') {
                                                const file = event.target.files[0]
                                                const fileBase64 = await fileToBase64(file)
                                                setBase64({ ...base64, [field.id]: fileBase64 })
                                            }
                                            field.onChange && field.onChange(event)
                                        }}
                                        defaultValue={field.defaultValue}
                                    />
                                }


                                {field?.type == 'select2' &&
                                    <FormControl
                                        style={{
                                            margin: '10px 8px'
                                        }}
                                    >
                                        <InputLabel>{getString(field.label)}</InputLabel>
                                        <Select
                                            name={field.id}
                                            disabled={disabled}
                                            value={selectValues[field.id]}
                                            required={field.required}
                                            multiple={field.multiple}
                                            label={getString(field.label)}
                                            style={{
                                                width: `25ch`,
                                            }}
                                            onChange={event => {
                                                field.onChange && field.onChange(event);
                                                handleChange(event, field.id);
                                            }}
                                        >
                                            {field.options?.map(option => (
                                                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                }

                                {field?.type == 'select' &&
                                    <FormControl
                                        style={{
                                            margin: '10px 8px'
                                        }}
                                    >
                                        <InputLabel>{getString(field.label)}</InputLabel>
                                        <Select
                                            name={field.id}
                                            disabled={disabled}
                                            value={selectValues[field.id]}
                                            required={field.required}
                                            multiple={field.multiple}
                                            label={getString(field.label)}
                                            style={{
                                                width: `25ch`,
                                            }}
                                            onChange={(event) => { handleChange(event, field.id) }}

                                        >
                                            {field.options?.map(option => (
                                                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                }
                                {
                                    field.type == 'google-autocomplete' &&
                                    <PlacesAutocomplete
                                        value={value}
                                        key={index}
                                        onChange={value => {
                                            setValue(value)
                                        }}
                                    >
                                        {
                                            ({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <Autocomplete
                                                    freeSolo
                                                    disableClearable
                                                    options={suggestions.map((option) => option.description)}
                                                    renderInput={(params) => {
                                                        return (
                                                            <TextField
                                                                {...params}
                                                                required={field.required}
                                                                style={{ margin: `10px 8px` }}
                                                                type={field.type}
                                                                name={field.id}
                                                                disabled={disabled}
                                                                label={getString(field.label)}
                                                                {...getInputProps()}
                                                            />
                                                        )
                                                    }}
                                                />
                                            )
                                        }
                                    </PlacesAutocomplete>
                                }
                            </Fragment>
                        )
                    }
                    )}
                    <div style={{ display: `flex`, justifyContent: 'flex-end', padding: '20px 0', margin: '10px 0' }}>
                        <Button variant="contained" type="submit" onClick={refreshPage}>{getString(`Submit`)}</Button>
                    </div>
                </div>
            </Box >
            <div style={{ display: `flex`, justifyContent: 'flex-start', padding: '20px 0' }}>
                <Button variant="contained" onClick={() => { changeMode('index'); setId(0); setViewId(0) }}> {getString(`Go Back`)} </Button>
            </div>

            {route == 'agents' && <Order onlyShow={true} agent_id={id} />}

        </>
    )
}

export default Form;