import { createContext, useEffect, useState } from 'react';
import { defaultLanguage } from '../config';
import { AppStrings } from '../utils/strings';

const LanguageContext = createContext({
    strings: {},
    currentLanguage: defaultLanguage,
    getString: (key) => { },
    changeLanguage: function (lang, callback) { },
});

export function LanguageContextProvider({ children }) {
    const [strings, setStrings] = useState(AppStrings[defaultLanguage]);
    const [currentLanguage, selCurrentLanguage] = useState(defaultLanguage);

    
    function changeLanguageHandler(lang, callback) {
        selCurrentLanguage(lang)
        setStrings(AppStrings[lang])
        callback && callback(AppStrings[lang])
    }
    
    const getString = (key) => {
        return strings[key] ? strings[key] : `${String(key.toUpperCase())}`
    }

    const context = {
        strings,
        currentLanguage,
        getString,
        changeLanguage: changeLanguageHandler
    };


    return (
        <LanguageContext.Provider value={context}>
            {children}
        </LanguageContext.Provider>
    );
}

export default LanguageContext;