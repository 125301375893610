import { version } from "../../config";
import Elbruz from "../Elbruz"
function LoginLayout({ children }) {
    return (
        <div style={{ background: '#1976d2', height: `100vh`, width: `100%`, padding: `20px`, display: `flex`, flexDirection: `column`, justifyContent: `center`, alignItems: `center` }}>
            <Elbruz />
            <div style={{ height: `100%`, width: `100%`, display: `flex`, justifyContent: `center`, alignItems: `center` }}
            >
                {children}
            </div>
            <span style={{ color: `#fff` }}>v {version}</span>
        </div>
    )
}

export default LoginLayout;