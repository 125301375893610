import { useState } from "react";
import CRUD from "../CRUD";

function Category() {
    const [cells, setCells] = useState([
        {
            id: 'name',
            numeric: false,
            disablePadding: false,
            label: 'Name',
        },
        {
            id: 'total',
            numeric: false,
            disablePadding: false,
            label: 'Total Products',
            getValue: (row) => {
                return String(row.products.length) || '-'
            }
        }
    ])

    const [fields, setFields] = useState([
        {
            id: 'name',
            required: true,
            label: 'Name',
            type: 'text'
        }
    ])


    return (
        <CRUD
            cells={cells}
            title={`Categories`}
            titleSingle={`Category`}
            route={`categories`}
            fields={fields}
        />
    );
}

export default Category;